<template>
    <div>
        <!-- App Header -->
        <div class="appHeader no-border transparent position-absolute">
            <div class="left">
                <!--                <a href="javascript:;" class="headerButton goBack">-->
                <!--                    <ion-icon name="chevron-back-outline"></ion-icon>-->
                <!--                </a>-->
            </div>
            <div class="pageTitle">
                <router-link :to="{name: 'MetricsWallet'}" class="headerButton">
                    <img src="../assets/img/metriclogo_color.png" alt="logo" class="logo">
                </router-link>
            </div>
            <div class="right">
<!--                <a href="javascript:void(0)" class="headerButton">-->
<!--                    -->
<!--                </a>-->
            </div>
        </div>
        <!-- * App Header -->

        <!-- App Capsule -->
        <div id="appCapsule">
            <!-- carousel slider -->
            <div class="carousel-slider splide">
                <div class="splide__track">
                    <ul class="splide__list">
                        <li class="splide__slide p-2">
                            <img src="../assets/img/mtw_hw_token.jpg" alt="alt" class="imaged w-100 square mb-4" style="width: 200px!important;height:250px">
                            <h2 class="mt-1">Private</h2>
                            <p>Store your private keys and know that it will never leave the device until you say so.</p>
                        </li>
                        <li class="splide__slide p-2">
                            <img src="../assets/img/sample/photo/vector2.png" alt="alt" class="imaged w-100 square mb-0">
                            <h2 class="mt-1">Simple</h2>
                            <p>
                                When you confirm a payment, the transaction is signed inside the hardware wallet and the output is sent to the Metrics app
                            </p>
                        </li>
                        <li class="splide__slide p-2">
                            <img src="../assets/img/sample/photo/verified.png" alt="alt" class="imaged w-100 square mb-0" style="width: 200px!important;height:200px">
                            <h2 class="mt-4">Secured</h2>
                            <p>
                                Your wallets are more secure than the other alternatives because they are very hard to hack
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- * carousel slider -->
            <div class="carousel-button-footer">
                <div class="row">
                    <div class="col-6">
                        <router-link :to="{name: 'MetricsWallet'}" class="btn btn-outline-secondary btn-lg btn-block">
                            Back to Wallet
                        </router-link>
                    </div>
                    <div class="col-6">
                        <a href="javascript:void(0)" class="btn btn-primary btn-lg btn-block" @click="apply">
                            <ion-icon name="hardware-chip-outline"></ion-icon> Apply
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <card-application-form context="TOKEN" :is-qualified="is_qualified"/>
    </div>
</template>

<script>
import cardApplicationForm from '../components/modals/cardApplicationForm';

export default {
    name: 'HWToken',
    data(){
      return {
          splide_options: {
              // gap: 10,
              autoplay: true,
              arrows: false,
              // padding: 10,
              pagination: false,
              fixedWidth: '100%',
              // fixedHeight: '200px',
              rewind: true
          },
          is_qualified: false
      }
    },
    methods: {
      async apply(){
          this.$loader.show()
          let response = await this.$store.dispatch('user/getUserProfile', this.$store.getters['user/getUser'].id);
          if(response.status){
              if(response.data.data.hwEnabled){
                  this.is_qualified = true;
                  $('#cardApplicationForm').modal('show')
              }else{
                  this.$store.dispatch('notification/display_noti', {
                      message: "Make a deposit of $250,000 and above to be eligible for this offer",
                  })
              }
          }else{
              this.$store.dispatch('notification/display_noti', {
                  message: "Unable to complete operation",
              })
          }
          this.$loader.hide()
      }
    },
    components: {
        cardApplicationForm
    },
    mounted(){
        new Splide('.carousel-slider', this.splide_options).mount();
    }
};
</script>

<style scoped>

</style>